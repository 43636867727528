import React from 'react';
import styles from './TitleAndContent.module.scss';
import ReactMarkdown from 'react-markdown';

const TitleAndContent = ({ title, content, dividerColour }) => {
    return (
        <div className={styles.TitleAndContent}>
            <h2 className={styles.Title}>{title}</h2>
            <div
                styles={{ backgroundColor: dividerColour }}
                className={styles.Divider}
            />
            <ReactMarkdown className={styles.Content} source={content} />
        </div>
    );
};

export default TitleAndContent;
