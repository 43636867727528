import React from 'react';
import classnames from 'classnames';
import ReactMarkdown from 'react-markdown';
import { Location } from '@reach/router';
import styles from './HomePageCalculator.module.scss';
import { Calculator } from '../../../../components/CBILSCalculator/CBILSCalculator';

export const HomePageCalculator = ({
    title,
    content,
    className,
    calculatorDescriptionPosition = true
}) => {
    return (
        <div className={classnames(styles.HomePageCalculator, className)}>
            <div className={styles.text}>
                <Location>
                    {({ location }) => {
                        if (location.pathname === '/cbils/') {
                            return <h3>{title}</h3>;
                        } else {
                            return <h1>{title}</h1>;
                        }
                    }}
                </Location>
                {calculatorDescriptionPosition && (
                    <ReactMarkdown source={content} />
                )}
            </div>
            <Calculator />
            {!calculatorDescriptionPosition && (
                <div className={styles.text}>
                    <ReactMarkdown className={styles.bottom} source={content} />
                </div>
            )}
        </div>
    );
};
