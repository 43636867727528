import React from 'react';
import MetaData from '../../../components/Layout/MetaData';
import { graphql } from 'gatsby';
import styles from './CBILSCalculatorPage.module.scss';
import NavBar from '../../../components/NavBar/NavBar';
import Footer from '../../../components/Footer/Footer';
import TitleAndContent from 'aphrodite-shared/ui/TitleAndContent/TitleAndContent';
import CBILSCtaWidget from 'aphrodite-shared/ui/CBILSCtaWidget/CBILSCtaWidget';
import { HomePageCalculator } from '../Homepage/HomePageCalculator/HomePageCalculator.jsx';
import DynamicTrustpilot from 'aphrodite-shared/ui/DynamicTrustpilot/DynamicTrustpilot';

const CBILSCalculatorPage = ({ data }) => {
    const { contentfulCalculatorPage: pageData } = data;
    const {
        cbilsCtaWidget,
        calculatorCopy,
        contentPieces,
        titleTag,
        metaDescription,
        allowSearchEngineScraping,
        url,
        keyFeatures
    } = pageData;

    return (
        <div className={styles.CBILSCalculatorPage}>
            <MetaData
                metaData={{
                    titleTag,
                    metaDescription,
                    allowSearchEngineScraping,
                    url
                }}
            />
            <NavBar />
            <HomePageCalculator
                title={calculatorCopy.title}
                content={calculatorCopy.content.content}
            />

            <div className={styles.CalculatorInstructions}>
                <div className={styles.StepByStep}>
                    <h2> How does our CBILS loan calculator work?</h2>
                    <ol>
                        <li>Put in how much you'd like to borrow </li>
                        <li>Choose how long for (up to 5 years)</li>
                        <li>See how much your monthly repayments will be</li>
                        <li>Click ‘Apply’ when you’re done</li>
                    </ol>
                </div>
                <p>
                    We've calculated your repayments using a rate of 10.2% APR –
                    this only includes the interest you pay after the first year
                    as the Government covers that. This means that the first
                    year will always show £0.
                </p>
            </div>
            {keyFeatures && (
                <TitleAndContent
                    title={keyFeatures.title}
                    content={keyFeatures.content.content}
                />
            )}
            {contentPieces[0] && (
                <TitleAndContent
                    title={contentPieces[0].title}
                    content={contentPieces[0].content.content}
                />
            )}
            <CBILSCtaWidget
                className={styles.CBILSCtaWidget}
                title={cbilsCtaWidget.title}
                subtitle={cbilsCtaWidget.subtitle.subtitle}
                primaryButton={{
                    ...cbilsCtaWidget.primaryButton
                }}
                secondaryButton={{
                    ...cbilsCtaWidget.secondaryButton
                }}
            />

            <div className={styles.trustWrapper}>
                <div className={styles.trustPilot}>
                    <DynamicTrustpilot />
                </div>
                <div className={styles.bbb}>
                    <a
                        href="https://www.british-business-bank.co.uk/ourpartners/coronavirus-business-interruption-loan-scheme-cbils-2/"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                            alt="british business bank"
                            src="https://images.ctfassets.net/835lsoe3tdrh/6IzwqY2vocktUhiJOjkYeS/acd09fcdcb622ead2804d4e00715c305/BBB_logo_only.png"
                        />
                    </a>
                    <p>
                        <span>CBILS</span> Accredited Lender
                    </p>
                </div>
            </div>
            {contentPieces.map((content, i) => {
                if (i === 0) return null;
                return (
                    <TitleAndContent
                        title={content.title}
                        content={content.content.content}
                    />
                );
            })}
            <Footer />
        </div>
    );
};

export default CBILSCalculatorPage;

export const query = graphql`
    query CalculatorPageQuery($id: String!) {
        contentfulCalculatorPage(id: { eq: $id }) {
            name
            titleTag
            url
            metaDescription
            allowSearchEngineScraping
            cbilsCtaWidget {
                title
                subtitle {
                    subtitle
                }
                primaryButton {
                    buttonText
                    url
                }
                secondaryButton {
                    buttonText
                    url
                }
            }
            calculatorCopy {
                title
                content {
                    content
                }
            }
            contentPieces {
                title
                content {
                    content
                }
            }
            keyFeatures {
                title
                content {
                    content
                }
            }
        }
    }
`;
